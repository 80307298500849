<template>
	<div class="mainBox">
		<img :src="banner" style="width: 100%;height: auto;" />
		<div class="formBox" v-if="cardDetail">
			<van-cell-group inset>
				<div class="title">订单信息</div>
				<van-field v-model="cardDetail.card" label="卡号" label-width="70px" readonly />
				<van-field v-model="cardDetail.zeo_name" label="产品名" label-width="70px" readonly />
				<van-field v-model="cardDetail.flagtime" label="激活时间" label-width="70px" readonly />
				<van-field v-model="cardDetail.statusName" label="订单状态" label-width="70px" readonly />
				<van-field v-model="cardDetail.ordertime" label="发货时间" label-width="70px" readonly />
				<van-field v-model="cardDetail.orders_type" label="快递公司" label-width="70px" readonly />
				<van-field v-model="cardDetail.orders" label="快递单号" label-width="70px" readonly />
				<van-field :model-value="cardDetail.username + ' ' + cardDetail.tel" label="收件人" label-width="70px"
					readonly />
				<van-field :model-value="cardDetail.province + cardDetail.city + cardDetail.area + cardDetail.address"
					label="收件信息" label-width="70px" readonly />
			</van-cell-group>
			<div style="margin: 16px;text-align: left;">
				<van-button round type="primary" native-type="submit" color="#000000"
					@click="express(cardDetail.orders)">
					查询快递
				</van-button>
				<van-button round type="primary" native-type="submit" color="#000000"
					@click="progress(cardDetail.ordersn)">
					查看订单进度
				</van-button>
			</div>
			<!-- 'https://m.baidu.com/s?ie=utf-8&wd=' + order + '&com=auto' -->
		</div>
		<div class="formBox" v-else>
			<div style="padding: 80px 50px;">
				<van-loading size="24px" type="spinner" v-if="!loading" vertical>订单加载中</van-loading>
			</div>
		</div>
		<div class="footer">&copy;Sanmark<div class="istero" @click="toistero">起零公司提供技术支持</div>
		</div>
	</div>
</template>
<script>
	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import {
		showToast,
		showDialog
	} from 'vant';
	import {
		showConfirmDialog
	} from 'vant';

	import {
		areaList
	} from '@vant/area-data';

	import {
		getBanner,
		getOrderDetail
	} from '@/api/home';
	export default {
		name: 'App',
		data() {
			return {
				banner: "",
				show: false,
				loading: false,
				cardDetail: ""
			}
		},
		created() {
			let that = this
			that.sn = that.$route.query.sn
			that.getOrderDetail()
			that.getBanner()
		},
		setup() {
			return {

			};

		},
		methods: {
			orderlist(e) {
				let that = this
				that.$router.back()
			},
			express(e) {
				window.open('https://m.baidu.com/s?ie=utf-8&wd=' + e + '&com=auto')
			},
			progress(e) {
				let that = this
				that.$router.push({
					path: "/order-progress",
					query: {
						sn: e
					}

				})
			},
			getBanner() {
				let that = this
				getBanner({}).then(res => {
					if (res.code == 200) {
						that.banner = res.data.zb_img
					}
				})
			},
			back() {
				let that = this
				localStorage.clear();
				localStorage.setcardDetail("access_token", "");
				that.$router.push({
					path: "/"
				})
			},

			formattedTime(date) {
				const year = date.getFullYear();
				const month = ("0" + (date.getMonth() + 1)).slice(-2);
				const day = ("0" + date.getDate()).slice(-2);
				const hour = ("0" + date.getHours()).slice(-2);
				const minute = ("0" + date.getMinutes()).slice(-2);
				const second = ("0" + date.getSeconds()).slice(-2);
				return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
			},
			getOrderDetail() {
				let that = this
				getOrderDetail({
					sn: that.sn,
				}).then(res => {
					if (res.code == 200) {
						that.cardDetail = res.data
						that.loading = true
					} else {
						that.$router.push({
							path: "/",
						})
					}

				})
			},
		}


	}
</script>
<style>
	body {
		background: rgb(24, 24, 24);
	}

	.mainBox {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}



	.mainBox .formBox {
		width: 85%;
		padding: 5px 5px 15px 5px;
		text-align: center;
		background: #ffffff;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		border-radius: 10px;
	}

	.mainBox .title {
		font-size: 14px;
		padding: 15px 0px;
	}

	.mainBox .footer {
		font-size: 12px;
		padding: 30px 0px;
		color: #676767;
		text-align: center;
	}

	.mainBox .footer .istero {
		font-size: 11px;
		border-bottom: #4c4c4c solid 1px;
		margin-top: 5px;
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
	}
</style>